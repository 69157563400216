import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';

interface PremiumButtonProps {
  sx?: any;
  path?: string;
}

const PremiumButton: React.FC<PremiumButtonProps> = ({ sx }) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: 'auto',
        height: 'auto',
        borderRadius: '10px',
        borderColor: 'primary.main',
        position: 'relative',
        cursor: 'pointer',
        color: 'primary.main',
        border: '2px solid',
        padding: '5px',
        ...sx,
      }}
      className="plus-button"
      onClick={() => {
        navigate('/dashboard/premium');
      }}
    >
      <AutoAwesomeOutlinedIcon/>
      J'essaie le Premium
    </Box>
  );
};

export default PremiumButton;

