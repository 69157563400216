import { User } from '../types/user.type';

export const checkActive = async (user: User) => {
  const isActive =
    user.is_admin || user.role === 'client'
      ? true
      : user.subscription?.status === 'active';
  const isTrial = user.is_trial_active || false
  return isActive || isTrial;
};

